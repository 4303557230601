#convite-container {
    width: 100%;
}

.convite-wrapper {
    max-width: 1000px;
    height: 600px;
    margin: 0 auto;
    padding: 20px;
    width: 100%;
    position: relative;
}

.convite-titulo {
    font-size: 2rem;
    text-align: center;
    color: var(--cor-primaria);
}

.convite-titulo-alternativo {
    color: var(--cor-secundaria);
    font-size: 2rem;
}

.convite-imagem {
    width: 90%;
}