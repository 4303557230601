#funcionalidade-container {
    width: 100%;
}

.funcionalidade-wrapper {
    max-width: 1000px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 60px 0;
    position: relative;
}

.funcionalidade-titulo {
    text-align: center;
    font-size: 2rem;
}

.funcionalidade-texto {
    width: 30%;
    font-size: 1.5rem;
    padding: 50px 0;
}


.funcionalidade-texto1 {    
    text-align: left;
}

.funcionalidade-texto2 {
    text-align: center;
    margin: 0 auto;
}

.funcionalidade-texto3 {
    text-align: right;
    margin-left: auto;
}

.funcionalidade-imagem {
    height: 400px;
    position: absolute;
    right: 0;
    top: -100px;
}

.funcionalidade-imagem2 {
    height: 400px;
    position: absolute;
    left: 0;
    bottom: -30px;
}

@media (max-width: 1000px) {
    .funcionalidade-imagem {
        top: 0;
    }

    .funcionalidade-imagem2 {
        bottom: 0;
    }

    .funcionalidade-wrapper {
        padding: 60px;
    }
}

@media (max-width: 700px) {
    .funcionalidade-imagem {
        width: 250px;
        height: auto;
        top: 100px;
    }

    .funcionalidade-imagem2 {
        width: 250px;
        height: auto;
        bottom: 100px;
    }
}