.popup-container {
    position: absolute;
    width: 100vw;
    height: 100vh;
}

.popup-wrapper {
    width: 300px;
    padding: 10px;
    border-radius: var(--raio-borda);
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    text-align: center;
}

.popup-fundo {
    width: 100vw;
    height: 100vh;
    position: relative;
    background-color: gray;
    opacity: 0.5;
    z-index: 1;
}

.popup-wrapper {
    position:absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%);
    z-index: 2;
}

.popup-botao-wrapper {
    display: flex;
    gap: 20px;
}