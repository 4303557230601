.div-imagem-checkout{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.container-form-checkout{
    align-self: center;
    width: 90%;
    background-color: white;
    border-radius: var(--raio-borda);
    box-shadow: var(--sombra);;
}

.secao-form-botao-container{
    width: 100%;
    display: flex;
    justify-content: end;
    padding: 0px 20px;
    margin-top: 10px;
}
/* .container-secao-form-checkout{
    padding: 20px;
    width: 100%;
}

.secao-form-inputs-checkout{
    display: grid;
    grid-template-columns:repeat(5, 1fr);
    background-color: white;
    border: 1px solid rgb(119, 118, 118);
    margin-top: 8px;
    padding: 10px;
    flex-wrap: wrap;
} */