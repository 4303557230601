#parceiros-container {
    width: 100%;
    background-color: var(--cor-gelo);
}

.parceiros-wrapper {
    max-width: 1000px;
    background-color: var(--cor-gelo);
    margin: 0 auto;
    display:flex;
    flex-direction: column;
    align-items: center;
    padding: 50px;
    gap: 50px;
}

.parceiros-titulo {
    font-size: 2rem;
}

.parceiros-imagem-container {
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.parceiros-imagem-wrapper {
    width: 100px;
    height: 100px;
    background-color: gray;
    border-radius: 500px;
}

.parceiros-imagem {
    width: 100%;
    height: 100%;
    border-radius: var(--raio-borda-completo);
}