.cadastro-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.cadastro-wrapper {
    display: flex;
    box-shadow: var(--sombra);
}

.cadastro-imagem-principal-container {
    width: 400px;
    height: 500px;
    background-image: url(../../../../../assets/image/cadastropromo.png);
    background-repeat: no-repeat;
    background-position: right;
    background-size: cover;
}

.cadastro-input-wrapper {
    width: 400px;
    height: 500px;
    background-color: white;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.cadastro-titulo {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
}

.cadastro-links-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
}

.link-esqueci-senha {
    text-align: right;
    color: red;
}

.paragrafo-link-login {
    text-align: center;
}



