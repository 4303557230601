.container-secao-form-checkout{
    padding: 20px;
    width: 100%;
}

.secao-form-inputs-checkout{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    background-color: white;
    border: 1px solid rgb(119, 118, 118);
    margin-top: 8px;
    padding: 10px;
}
