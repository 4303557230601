@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,700;1,500&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
    outline: none;
    border: none;
    font-size: 1rem;
    font-family: 'Open Sans', sans-serif;
    border-spacing: 0;
}

:root {
    --cor-primaria:#FF6F43;
    --cor-laranja-avermelhado: #EF6936;
    --cor-secundaria:#00BED2;
    --cor-azulrgb-claro: #2b798a;
    --cor-azul-marinho-claro: #202D43;
    --cor-azul-marinho-escuro: #192033;
    --cor-texto-preto: #000000;
    --cor-gelo: #F3F3F3;
    --cor-container: #FFF;
    --sombra: 0 1px 2px gray;
    --raio-borda: 5px;
    --raio-borda-container: 10px;
    --raio-borda-completo: 500px;
    --gap: 10px;
}

a {
    color: var(--cor-texto-preto);
}

a:hover {
    color: var(--cor-secundaria);
}

.btn {
    width: 100px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--cor-primaria);
    color: var(--cor-primaria);
    border-radius: var(--raio-borda);
    cursor:pointer;
    background-color: transparent;
    padding: 10px;
}

.btn-lg {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--cor-primaria);
    color: white;
    border-radius: var(--raio-borda);
    cursor:pointer;
    background-color: transparent;
    padding: 10px;
}

.btn-icone {
    width: 50px;
}

.btn-com-fundo {
    background-image: linear-gradient(to right, var(--cor-primaria), var(--cor-laranja-avermelhado));
    color: white;
    font-weight: bold;
}

.btn-secundario {
    border: 1px solid var(--cor-secundaria);
    color: var(--cor-secundaria);
    background-color: white;
}

.btn-buscar {
    border-radius: var(--raio-borda);
    margin-top: auto;
    border: 1px solid var(--cor-secundaria);
    color: var(--cor-secundaria);
    background-color: white;
}

.btn-com-fundo:active {
    background-image: linear-gradient( var(--cor-laranja-avermelhado),  var(--cor-laranja-avermelhado))
}

.btn-com-fundo:hover {
    box-shadow: var(--sombra);
}

.btn-excluir {
    color: red;
    border: 1px solid red;
}

/* INPUT NORMAL */
input {
    border: 1px solid gray;
    border-radius: var(--raio-borda);
    padding: 5px;
}

.input {
    border: 1px solid gray;
    border-radius: var(--raio-borda);
    padding: 5px;
    background-color: transparent;
}

/* INPUT LARGO */
.input-lg {
    width: 100%;
    height: 60px;
    border-radius: var(--raio-borda);
    border: 1px solid lightgray;
    padding: 10px;
}

.input-lg:focus {
    border: 2px solid var(--cor-primaria);
}

.dropdown-option {
    width: 100%;
    padding: 10px 0;
    cursor: pointer;
    background-color: white;
}

.dropdown-option:hover {
    background-color: lightgray;
}