.slide-container {
    width: 100%;
    height: 500px;
    background-color: black;
    position: relative;
    overflow: hidden;
}

.slide-imagem-fundo {
    opacity: 0.4;
    position: absolute;
    width: 100%;
    height: auto;
    min-width: 1000px;
    min-height: 500px;
    z-index: 1;

}

.slide-wrapper {
    max-width: 1000px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    gap: 20px;
}

.slide-titulo {
    color: var(--cor-primaria);
    font-size: 2.5rem;
}

.slide-texto {
    color: white;
    font-size: 1rem;
    font-weight: bold;
    margin-top: 20px;
}

.slide-imagem-promo {
    height: 50%;
}

@media (max-width: 1200px) {
    .slide-imagem-promo {
        display: none;
    }

    .slide-titulo {
        text-align: center;
    }

    .slide-texto {
        text-align:center;
    }
}
