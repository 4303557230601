#prova-social-container {
    width: 100%;
    background-color: var(--cor-primaria);
}

.prova-social-wrapper {
    max-width: 1000px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    gap: 50px;
    flex-wrap: wrap;
    padding: 60px 0;
}

.prova-social-conteudo-container {
    width: 300px;
    height: 400px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    gap: 20px;
    align-items: center;
    padding: 10px;
}

.prova-social-imagem-wrapper {
    width: 100px;
    height: 100px;
    border-radius: var(--raio-borda-completo);
    background-color: gray;
}

.prova-social-conteudo {
    font-size: 1.25rem;
}

.prova-social-imagem {
    width: 100px;
    height: 100px;
    border-radius: var(--raio-borda-completo);
}