.sistema-wrapper-lista {
    display: flex;
    height: calc(100vh - 60px);
    gap: 10px;
}

.sistema-conteudo-wrapper {
    display: flex;
    flex-direction: column;
    width: calc(100vw - 20vw);
    gap: 10px;
}

.sistema-input-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 10px;
    background-color: white;
    border-radius: var(--raio-borda-container);
    box-shadow: var(--sombra);
}

.sistema-form-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.sistema-section-os{
    width: 100%;
    display: flex;
    gap:20px;
    align-items: center;
}

.sistema-thead {
    background-color: var(--cor-secundaria);
    color: white;
}

.sistema-tbody {
    display: block;
    overflow-y: scroll;
}

.sistema-tr-head {
    display: flex;
    box-shadow: var(--sombra);
    padding: 1rem;
}

.sistema-tr-body {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: white;
    box-shadow: var(--sombra);
    padding: 0.25rem;
    border-radius: var(--raio-borda);
    margin: 2.5px 0;
}

th {
    width: 100%;
}

td {
    width: 100%;
    display: flex;
    justify-content: center;
}

.sistema-form-e-botao-wrapper {
    display: flex;
    gap: 10px;
}

.sistema-input-wrapper-botao-wrapper {
    display: flex;
    gap: 10px;
    align-items: flex-end;
}

    .sistema-wrapper-detalhe {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    
    .sistema-detalhe-voltar {
        display: flex;
        gap: 10px;
        align-items: center;
    }
    
    .sistema-detalhe-botao {
        display: flex;
        gap: 10px;
        margin-left: auto;
    }
    .sistema-detalhe-input-wrapper {
        width: 100%;
        height: auto;
        background-color: white;
        box-shadow: var(--sombra);
        border-radius: var(--raio-borda);
        display: flex;
        flex-direction: column;
        padding: 10px;
        gap: 10px;
    
    }
    
    .sistema-detalhe-form {
        display: flex;
        align-items: center;
        gap: 10px;
        flex-wrap: wrap;
    }
    
    .sistema-detalhe-button-wrapper {
        display: flex;
        justify-content: right;
        gap: 10px;
    }

    .sistema-imagem-tabela-preco-item-wrapper {
        display:flex;
        align-items: center;
        gap: 10px;
    }

    .sistema-imagem-tabela-preco-item {
        width: 40px;
    }

    .sistema-select-tabela-preco-item {
        /* padding: 10px 0; */
    }

    .sistema-perfil-wrapper {
        width: 100%;
        height: 100%;
        background-color: white;
        border-radius: var(--raio-borda);
        box-shadow: var(--sombra);
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 10px;
    }
    
    .sistema-img-perfil-wrapper {
        width: 200px;
        height: 200px;
        border-radius: var(--raio-borda-completo);
        overflow: hidden;
    }
    
    .sistema-img-perfil {
        height: 200px;
    }

    .btn-dropdown-wrapper {
        position:relative;
    }

    .sistema-grafico-wrapper {
        width: 400px;
        height: 200px;
        background-color: white;
        box-shadow: var(--sombra);
        padding: 10px;
    }

    .grafico-sm {
        width: 200px;
    }

    .grafico-lg {
        width: 600px;
    }

    .sistema-grafico-container {
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
    }

    .sistema-sidebar-pedido-filtro {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .sistema-estrutura-cliente {
        width: 100%;
        height: 100%;
        display: flex;
        gap: var(--gap)
    }

    .sistema-sidebar-detalhe {
        width: 20%;
        height: 100%;
        background-color: white;
        border-radius: var(--raio-borda);
        box-shadow: var(--sombra);
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 10px;
    }

    .sistema-campo-detalhe {
        width: 80%;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .sistema-campo-detalhe-form {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        gap: 10px;
        background-color: white;
        border-radius: var(--raio-borda);
        box-shadow: var(--sombra);
        padding: 10px;
    }
    
    .sistema-campo-detalhe-button-wrapper {
        width: 100%;
        display: flex;
        gap: 10px;
    }

    .sistema-campo-detalhe-input-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr; 
        gap: 10px;
    }

    .sistema-campo-detalhe-table {
        width: 100%;
        height: 100%;
    }
