#sobre-container {
    width: 100%;
    background-image: linear-gradient(to left, var(--cor-secundaria), var(--cor-azulrgb-claro));
}

.sobre-wrapper {
    max-width: 1000px;
    height: 100%;
    margin: 0 auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: white;
    padding: 100px 0;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.sobre-conteudo-container {
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sobre-titulo {
    font-size: 2rem;
    text-align: center;
}

.sobre-texto {
    width: 90%;
    font-size: 1.5rem;
    text-align: center;
}