.footer-container {
    width: 100%;
    height: auto;
    background-image: linear-gradient(var(--cor-azul-marinho-claro), var(--cor-azul-marinho-escuro));
}

.footer-wrapper {
    max-width: 1000px;
    width: 100%;
    height: 100%;
    display: flex;
    min-width: 100%;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    flex-wrap: wrap;
    gap: 50px;
    padding: 10px 0;
} 

.footer-nav-container {
    display: flex;
    gap: 150px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.footer-nav {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.footer-texto {
    color: white;
    text-align: center;
}

.footer-logo {
    height: 100px;
}