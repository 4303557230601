#header-container {
    width: 100%;
    height: 50px;
    background-color: white;
    box-shadow: 0 0 2px gray;
    position: fixed;
    z-index: 100;
}

.header-wrapper {
    max-width: 1000px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
}

.logo-principal {
    height: 100%;
}

.header-nav-wrapper {
    display: flex;
    gap: 40px;
}

.header-nav-icone-wrapper {
    display: none;
}

.header-menu-icone-wrapper {
    display: none;
}

.header-nav-login-wrapper {
    display: flex;
    gap: 5px;
    align-items: center;
}

@media (max-width: 1200px) {

    .header-nav-icone-wrapper {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 500px;
    }

    .header-nav-wrapper {
        display: none;
    }
}

@media (max-width: 900px) {
    .header-container {
        width: 100%;
        height: 100px;
    }

    .header-wrapper {
        padding: 0 20px;
    }

    .header-nav-icone-wrapper {
        display: none;
    }

    .header-nav-login-wrapper {
        display: none;
    } 

    .header-menu-icone-wrapper {
        display: block;
    }
}
