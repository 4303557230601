/* INPUT NORMAL */
input {
    border: 1px solid gray;
    border-radius: var(--raio-borda);
    padding: 5px;
}

/* INPUT LARGO */
.input-lg {
    width: 100%;
    height: 60px;
    border-radius: var(--raio-borda);
    border: 1px solid lightgray;
    padding: 10px;
}

input:focus {
    border: 2px solid var(--cor-primaria);
}

.input-lg:focus {
    border: 2px solid var(--cor-primaria);
}